<template>
  <div class="main-box">
    <a-descriptions :data="personInfo" title="Person Info" :column="1" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ userInfo: "userInfo" }),
    personInfo() {
      // companyName: ""
      // email: ""
      // industry: ""
      // status: false
      return [
        { label: "Email", value: this.userInfo.email },
        { label: "Company Name", value: this.userInfo.companyName },
        { label: "Industry", value: this.userInfo.industry },
        { label: "status", value: this.userInfo.status ? "Active" : "UnActive" }
      ];
    }
  },
  mounted() {
    console.log("userInfo", this.userInfo);
  }
};
</script>

<style lang="scss" scoped>

</style>
